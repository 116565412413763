import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';
import { googleChat } from '../../../util/googleChat';
import css from './BuyerRequestForInfoForm.module.css';

const KEY_CODE_ENTER = 13;

const BuyerRequestForInfoFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        signupSuccess,
        requestForInfoError,
      } = fieldRenderProps;
      // referer details
      let urlParams = null;
      let a = null;
      let invitedEmail = null;

      if (typeof window !== 'undefined') {
        urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
        a = urlParams.get('a') ? urlParams.get('a').replace(' ', '+') : null;
        invitedEmail = urlParams.get('ie') ? urlParams.get('ie').replace(' ', '+') : null;
      }

      // const sendGoogleChatNotification = () => {
      //   if (a !== null) {
      //     googleChat('user-visit.invited', { invitedEmail, refererEmail });
      //   }
      // };
      // useEffect(
      //   sendGoogleChatNotification,
      //   [] // <-- empty dependency array
      // );

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const companyNameRequiredMessage = 'Company name is required';
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      const companyRegNoRequiredMessage = 'Company reg no is required';
      const companyRegNoRequired = validators.required(companyRegNoRequiredMessage);

      const companyVATNoRequiredMessage = 'Company VAT no is required';
      const companyVATNoRequired = validators.required(companyVATNoRequiredMessage);

      const deliveryAddressRequiredMessage = 'Delivery address is required';
      const deliveryAddressRequired = validators.required(deliveryAddressRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      const error = requestForInfoError ? (
        <span className={css.error}>
          An error has occured.<br></br>Please try again or{' '}
          <a href="mailto:jack@weareplatter.com" target="_blank">
            contact us
          </a>{' '}
          for help.
        </span>
      ) : null;

      return signupSuccess ? (
        <div>
          <p>Thank you.</p>
          <p>
            Congratulations! We will be in contact with your free<br></br>ordering platform login in
            the next 24hrs.
          </p>
          <p>
            Platter will enable you to save time, reduce costs and<br></br>eliminate food waste.
          </p>
        </div>
      ) : (
        <Form className={classes} onSubmit={handleSubmit} data-cy="buyerRequestForInfoForm">
          <h2 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.youhavebeen.line1" />
            <br />
            <FormattedMessage id="AcceptInviteForm.header.youhavebeen.line2" />
          </h2>
          <h3 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.uksfirst.line1" />
          </h3>
          <h3 className={css.header}>
            <FormattedMessage id="AcceptInviteForm.header.uksfirst.line2" />
          </h3>
          {error}
          <div className={css.formContainer}>
            <div>
              <div className={css.hiddenFields}>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.a` : 'a'}
                  name="a"
                  defaultValue={a}
                />
              </div>
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
                data-cy="acceptInviteFormFirstName"
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
                data-cy="acceptInviteFormFamilyName"
              />
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.root}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                label={`Email ( If differant )`}
                placeholder="Company name"
                defaultValue={invitedEmail}
                validate={companyNameRequired}
                data-cy="acceptInviteFormCompanyName"
              />
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.root}
                type="text"
                id={formId ? `${formId}.companyName` : 'companyName'}
                name="companyName"
                label="Company name"
                placeholder="Company name"
                validate={companyNameRequired}
                data-cy="acceptInviteFormCompanyName"
              />
            </div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.companyRegNo` : 'companyRegNo'}
                name="companyRegNo"
                label="Company reg no"
                placeholder="Company reg no"
                validate={companyRegNoRequired}
                data-cy="acceptInviteFormCompanyRegNo"
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.companyVATNo` : 'companyVATNo'}
                name="companyVATNo"
                label="Company VAT no"
                placeholder="Company VAT no"
                validate={companyVATNoRequired}
                data-cy="acceptInviteFormCompanyVATNNo"
              />
            </div>

            <div className={css.name}>
              <FieldTextInput
                className={css.root}
                type="textarea"
                id={formId ? `${formId}.address1` : 'address1'}
                name="address1"
                label="Address 1 ( Accounts )"
                validate={deliveryAddressRequired}
                data-cy="acceptInviteFormAddress1"
                placeholder="Accounts Address"
              />
            </div>

            <div className={css.name}>
              <FieldTextInput
                className={css.root}
                type="textarea"
                id={formId ? `${formId}.address2` : 'address2'}
                name="address2"
                label="Address 2 ( Delivery - Leave blank if same as acocunts )"
                data-cy="acceptInviteFormAddress2"
                placeholder="Delivery Address ( Delivery - Leave blank if same as acocunts )"
              />
            </div>
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              data-cy="buttonAcceptInvite"
            >
              <FormattedMessage id="AcceptInviteForm.accept" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

BuyerRequestForInfoFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

BuyerRequestForInfoFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BuyerRequestForInfoForm = compose(injectIntl)(BuyerRequestForInfoFormComponent);
BuyerRequestForInfoForm.displayName = 'BuyerRequestForInfoForm';

export default BuyerRequestForInfoForm;
